import styled, { css } from 'styled-components'
import { device } from 'src/styles/breakpoints'
import * as T from './_types'
import { grayscale, orange, white } from 'src/styles/colors'

const commonStyles = {
  cardParagraph: css`
    font-family: Inter, sans-serif, arial;
    text-align: center;
    margin: 0 0 8px 0;
  `,
}

export const EscolhaSuaAssinaturaContainer = styled.section`
  background: ${white};
`

export const Title = styled.h2`
  width: 80%;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: ${grayscale[500]};
  text-align: left;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 50px;
    width: 100%;
    text-align: center;
  }

  @media ${device.desktopLG} {
    font-size: 32px;
    line-height: 40px;
    text-align: left;
    margin-right: -5px;
  }

  @media ${device.desktopXXL} {
    font-size: 40px;
    line-height: 50px;
  }
`

export const Description = styled.p`
  font-family: Inter, sans-serif, arial;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[400]};

  margin-bottom: 24px;
  
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
  }

  @media ${device.desktopXXL} {
    margin-bottom: 40px;
  }
`

function selectCardVariationColors ({ variant }: T.CardAssinaturaVariants) {
  if (variant === 'black') {
    return (
      css`
        background: ${grayscale[500]};
        color: 'white';
        span {
          color: white;
        }
      `
    )
  }
  if (variant === 'white') {
    return (
      css`
        background: ${white};
        color: ${grayscale[500]};
        span {
          color: ${grayscale[500]};
        }
      `
    )
  }
}

export const CardAssinatura = styled.div<T.CardAssinaturaVariants>`
  ${(props: T.CardAssinaturaVariants) => selectCardVariationColors(props)}
  
  
  padding: 32px 10px;
  border: 1px solid ${grayscale[100]};
  border-radius: 8px;
  box-shadow: 0px 8px 16px 2px #16161614;
  cursor: pointer;
  
  width: 100%;
  max-width: 456px;
  height: 100%;
  min-height: 100%;
  
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  position: relative;
  

  @media ${device.tablet} {
    height: 209px;
  }

  @media ${device.desktopLG} {
    height: 226px;
  }

  @media ${device.desktopXXL} {
    height: 209px;
    max-width: 100%;
  }

`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media ${device.tablet} {
    flex-direction: row;
    margin-bottom: 32px;
  }
`

export const Tag = styled.img`
  position: absolute;
  top: -11px;
  right: 0%;

  width: 95px;
  height: 48px;

  @media ${device.tablet} {
    top: -12px;
    right: -15px;
  }

  @media ${device.desktopLG} {
    top: -15px;
    right: -16px;
  }

  @media ${device.desktopXXL} {
    width: 90px;
    height: 46px;

    top: -14px;
    right: -19px;
  }

`

export const SubscriptionType = styled.span`
  font-family: 'Sora', sans-serif, arial;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  margin-bottom: 8px;
`

function selectSubscriptionPriceColor ({ variant }: T.SubscriptionValueVariations) {
  if (variant === 'white') {
    return (
      css`
        color: ${white};
      `
    )
  }
  if (variant === 'orange') {
    return (
      css`
        color: ${orange[500]};
      `
    )
  }
}

export const SubscriptionPrice = styled.p<T.SubscriptionValueVariations>`
  ${(props: T.SubscriptionValueVariations) => selectSubscriptionPriceColor(props)}

  font-family: 'Sora', sans-serif, arial;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 8px;
`

export const SubscriptionPriceParcels = styled.span`
  font-family: 'Sora', sans-serif, arial;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
`

export const SubscriptionDescription = styled.span`
  ${commonStyles.cardParagraph}
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`

export const PromotionalCoupon = styled.span`
  ${commonStyles.cardParagraph}
  display: inline;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`

export const PromotionValidDate = styled.span`
  ${commonStyles.cardParagraph}

  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
`
