import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { WIDTH_MD } from 'src/styles/breakpoints'
import * as S from './style'
import { Button } from '@interco/inter-ui/components/Button'
interface IProps {
  openModal: () => void;
  oneLink: string;
}

const HeroBlackFriday = ({ openModal, oneLink }: IProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <S.HeroContainer
      className='justify-content-md-center d-md-flex bg-black'
      role='img'
      aria-label='Mão esquerda segurando um celular com o app do Duo Gourmet na tela e mão direita mostrando um cartão Black com um prato servido à mesa ao fundo.'
    >
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 pb-md-0 offset-md-6 px-0'>
            <S.Title>
              Plano Duo Gourmet com R$70 OFF + Cartão Black
            </S.Title>
            <S.Description>
              Utilize o cupom <b>BLACKDUO70</b> e assine o plano anual na Orange Friday com o último desconto do ano! Peça 2 pratos e pague por apenas 1 em milhares de restaurantes, e ainda ganhe um cartão Inter Black sem custo adicional.
            </S.Description>
            {width > WIDTH_MD ? (
              <Button
                fullWidth
                title='Assinar agora'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Assine o plano anual do Inter Duo Gourmet e ganhe um Cartão Black',
                    element_action: 'click button',
                    element_name: 'Assinar agora',
                   })
                }}
              >
                Assine e garanta seu desconto
              </Button>
            ) : (
              <Button
                fullWidth
                as='a'
                href={oneLink}
                title='Assinar agora'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Assine o plano anual do Inter Duo Gourmet e ganhe um Cartão Black',
                    element_action: 'click button',
                    element_name: 'Assinar agora',
                    redirect_url: oneLink,
                   })
                }}
              >
                Assine e garanta seu desconto
              </Button>
            )}

            <S.PromotionValidDate>*Promoção válida até 30/11</S.PromotionValidDate>
          </div>
        </div>
      </div>
    </S.HeroContainer>
  )
}

export default HeroBlackFriday
