import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import ImageWebp from 'src/components/ImageWebp'

import * as S from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
interface IProps {
  openModal: () => void;
  oneLink: string;
}

const WIDTH_MD = 720

const EscolhaSuaAssinaturaBlackFriday = ({ openModal, oneLink }: IProps) => {
  const windowWidth = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const arrayNumbers: number[] = [
    324, 300, 400, 453,
  ]

  const arrayHeight: number[] = [
    324, 300, 400, 453,
  ]

  return (
    <S.EscolhaSuaAssinaturaContainer id='escolha-sua-assinatura' className='py-5 d-flex align-items-center justify-content-center position-relative'>
      <div className='container'>
        <div className='row align-items-center justify-content-center justify-content-lg-between'>
          <div className='col-12 col-md-5 col-lg-5 px-0 px-xl-2 order-lg-last pt-md-4 pt-lg-5 mb-4 text-center text-md-right'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-assinatura-duo/image.webp'
              altDescription='Mão segurando um celular mostrando o app do Duo Gourmet e um cartão black na outra mão com uma refeição sobre a mesa ao fundo.'
              arrayNumbers={arrayNumbers}
              arrayNumbersHeight={arrayHeight}
            />
          </div>
          <div className='col-12 col-md-9 col-lg-6'>
            <div>
              <S.Title>
                Assine agora e aproveite
              </S.Title>
            </div>
            <S.Description>
              Com o Duo Gourmet, você tem acesso aos <strong>melhores restaurantes</strong> e economiza ao <strong>pagar um prato e ganhar outro</strong>.
            </S.Description>
            <S.CardWrapper>
              <S.CardAssinatura
                variant='black'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Assine agora e aproveite/Escolha sua assinatura',
                    element_action: 'click button',
                    element_name: 'Assinatura Anual',
                  })
                }}
              >
                <S.Tag src='https://central-imagens.bancointer.com.br/images-without-small-versions/tag-orange-friday/image.webp' />
                <S.SubscriptionType>Assinatura Anual</S.SubscriptionType>
                <S.SubscriptionPrice variant='white'>
                  <S.SubscriptionPriceParcels>
                    12x
                  </S.SubscriptionPriceParcels>
                  {' '}R$39,17
                </S.SubscriptionPrice>
                <S.PromotionalCoupon>
                  Utilize o cupom{' '}<strong>BLACKDUO70</strong>
                </S.PromotionalCoupon>
                <S.SubscriptionDescription>
                  Ganhe um Cartão Black
                </S.SubscriptionDescription>
                <S.PromotionValidDate>
                  *Promoção válida até 30/11
                </S.PromotionValidDate>
              </S.CardAssinatura>

              <S.CardAssinatura
                variant='white'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Assine agora e aproveite/Escolha sua assinatura',
                    element_action: 'click button',
                    element_name: 'Assinatura mensal',
                  })
                }}
              >
                <S.SubscriptionType>Assinatura mensal</S.SubscriptionType>
                <S.SubscriptionPrice variant='orange'>
                  R$ 70,00
                </S.SubscriptionPrice>
                <S.SubscriptionDescription>
                  por mês
                </S.SubscriptionDescription>
              </S.CardAssinatura>
            </S.CardWrapper>
            {windowWidth > WIDTH_MD ? (
              <div className='d-flex align-items-center justify-content-center'>
                <button
                  className='btn btn-orange--extra fs-14 fw-700 text-none mt-4 mt-md-0'
                  title='Aproveitar agora'
                  onClick={() => {
                    openModal()
                    sendDatalayerEvent({
                      section: 'dobra_04',
                      section_name: 'Assine agora e aproveite/Escolha sua assinatura',
                      element_action: 'click button',
                      element_name: 'Aproveitar agora',
                    })
                  }}
                >
                  Aproveitar agora
                </button>
              </div>
           ) : (
             <div className='w-100 d-flex align-items-center justify-content-center'>
               <a
                 href={oneLink}
                 target='_blank'
                 rel='noreferrer'
                 title='Aproveitar agora'
                 className='btn btn-orange--extra fs-14 fw-700 text-none mt-4 mt-md-0'
                 onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Assine agora e aproveite/Escolha sua assinatura',
                    element_action: 'click button',
                    element_name: 'Aproveitar agora',
                    redirect_url: oneLink,
                  })
                 }}
               >
                 Aproveitar agora
               </a>
             </div>
           )}
          </div>
        </div>
      </div>
    </S.EscolhaSuaAssinaturaContainer>
  )
}

export default EscolhaSuaAssinaturaBlackFriday
